<template>
  <b-modal
    ref="modal"
    title="Expediente"
    size="lg"
  >
    <div class="row mb-2">
      <div class="col-12">
        <div class="btn-group">
          <a
            :href="reportDownload()"
            target="_blank"
            class="btn btn-info"
          >Descargar reporte pagaré</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>Información de cliente</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="btn-group">
                <a
                  v-for="media of account.getAttr('customer', false).getAttr('mediable')"
                  :key="media.id"
                  :href="media.file_url"
                  class="btn btn-primary"
                  target="_blank"
                >
                  {{ customerMedias[media.type] }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>Información de la cuenta</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div
                v-for="fieldValue of account.getAttr('account_field_values')"
                :key="fieldValue.id"
                class="col-12"
              >
                <component
                  :is="types[fieldValue.account_type_field.type]"
                  :value="fieldValue"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import TextComponent from '@/views/components/customFields/TextComponent.vue'
import FileComponent from '@/views/components/customFields/FileComponent.vue'

export default {
  name: 'RecordAccountModal',
  components: {
    BModal,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    customerMedias: {
      front_id: 'Identificación frontal',
      back_id: 'Identificación trasera',
    },
    types: {
      1: TextComponent,
      2: TextComponent,
      3: FileComponent,
      4: TextComponent,
    },
  }),
  methods: {
    show() {
      this.$refs.modal.show()
    },
    reportDownload() {
      return `${process.env.VUE_APP_API_URL}/pagare-prestamo/${this.account.getAttr('id', false)}`
    },
  },
}
</script>

<style scoped>

</style>
