<template>
  <b-overlay :show="loading">
    <div
      v-if="account"
      class="card"
    >
      <div class="card-header">
        <h4 class="card-title">
          Información de la cuenta
        </h4>
        <div class="btn-group">
          <button
            class="btn-info btn"
            @click="openRecordAccountModal"
          >
            <feather-icon
              icon="FolderIcon"
            />
            Expediente
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Tipo de cuenta</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('account_type', false).getAttr('name')"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Tipo</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('account_type', false).getAttr('type')"
              >
            </div>
          </div>
          <div class="col-12">
            <h5>Cliente</h5>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Nombre</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('customer', false).getAttr('first_name')"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Apellido</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('customer', false).getAttr('last_name')"
              >
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Teléfono</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('customer', false).getAttr('phone')"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Correo</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('customer', false).getAttr('email')"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>CURP</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('customer', false).getAttr('curp')"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>RFC</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="account.getAttr('customer', false).getAttr('rfc')"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <table-component
      v-if="!loading"
      ref="tableComponent"
      :model="model"
      :show-form="false"
      :permissions="permissions"
      :init-filter="initFilter"
    >
      <template v-slot:actions>
        <b-button
          variant="outline-primary"
          class="btn-icon"
          @click="openTransactionFormModal"
        >
          <feather-icon
            icon="PlusIcon"
          />
        </b-button>
        <div class="ml-2">
          <flat-pickr
            v-model="rangeDate"
            class="form-control outline-primary"
            :config="{ mode: 'range'}"
            @on-close="changeFilter"
          />
        </div>
      </template>

    </table-component>

    <account-transaction-form-modal
      v-if="!loading"
      ref="transactionFormModal"
      :transaction-types="transactionTypes"
      title="Registrar transacción"
      :account_id="account.getAttr('id')"
      @save="save"
    />

    <record-account-modal
      v-if="!loading"
      ref="recordAccountModal"
      :account="account"
    />
  </b-overlay>
</template>
<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import AccountTransaction from '@/dc-it/models/AccountTransaction'
import { BOverlay, BButton } from 'bootstrap-vue'
import Account from '@/dc-it/models/Account'
import AccountTransactionFormModal from '@/views/components/modals/AccountTransactionFormModal.vue'
import GeneralService from '@/dc-it/services/GeneralService'
import flatPickr from 'vue-flatpickr-component'
import helpers from '@/dc-it/services/helpers'
import RecordAccountModal from '@/views/components/modals/RecordAccountModal.vue'

export default {
  name: 'TransactionsView',
  components: {
    RecordAccountModal,
    AccountTransactionFormModal,
    BOverlay,
    TableComponent,
    BButton,
    flatPickr,
  },
  data() {
    return {
      model: AccountTransaction,
      permissions: {
        create: false,
        update: false,
        delete: false,
      },
      loading: true,
      account: null,
      transactionTypes: [],
      rangeDate: '',
      startDate: null,
      endDate: null,
      initFilter: [],
      datePickerConfig: {
        mode: 'range',
      },
    }
  },
  mounted() {
    const { id } = this.$route.params

    this.endDate = new Date()
    this.startDate = new Date()
    this.endDate.setDate(this.endDate.getDate() + 1)
    this.startDate.setDate(this.endDate.getDate() - 30)
    this.rangeDate = `${helpers.dateFormat(this.startDate)} to ${helpers.dateFormat(this.endDate)}`
    this.initFilter = [
      {
        field: 'created_at',
        operator: '>=',
        value: helpers.dateFormat(this.startDate),
      },
      {
        field: 'created_at',
        operator: '<=',
        value: helpers.dateFormat(this.endDate),
      },
      {
        field: 'account_id',
        operator: '=',
        value: id,
      },
    ]

    Account
      .with(['account_type.transaction_types', 'account_field_values.account_type_field', 'customer.mediable'])
      .where('id', '=', id)
      .get()
      .then(accounts => {
        // eslint-disable-next-line prefer-destructuring
        this.account = accounts[0]
        this.transactionTypes = (this.account.getAttr('account_type', false).getAttr('transaction_types', false))
        this.loading = false
      })
  },
  methods: {
    changeFilter() {
      setTimeout(() => {
        const dates = this.rangeDate.split(' to ')
        if (dates.length < 2) {
          dates.push(dates[0])
        }
        this.endDate = new Date(`${dates[1]}T00:00:00`)
        this.endDate.setDate(this.endDate.getDate() + 1)
        this.startDate = new Date(`${dates[0]}T00:00:00`)
        this.$refs.tableComponent.filterFields = [
          {
            field: 'created_at',
            operator: '>=',
            value: helpers.dateFormat(this.startDate),
          },
          {
            field: 'created_at',
            operator: '<=',
            value: helpers.dateFormat(this.endDate),
          },
          {
            field: 'account_id',
            operator: '=',
            value: this.account.getAttr('id'),
          },
        ]
        this.$refs.tableComponent.updateData()
      }, 100)
    },
    openTransactionFormModal() {
      this.$refs.transactionFormModal.show()
    },
    openRecordAccountModal() {
      this.$refs.recordAccountModal.show()
    },
    save(model) {
      this.loading = true
      GeneralService.getInstance().save('account_transactions', model)
        .then(() => {
          this.changeFilter()
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
