<template>
  <div class="form-group mb-2">
    <label>{{ value.account_type_field.name }}</label>
    <input
      class="form-control"
      type="text"
      disabled
      :value="value.value"
    >
  </div>
</template>

<script>
export default {
  name: 'TextComponent',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
