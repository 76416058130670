<template>
  <a
    :href="value.value"
    class="btn btn-primary mb-2"
    target="_blank"
  >
    {{ value.account_type_field.name }}
  </a>
</template>

<script>
export default {
  name: 'FileComponent',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
